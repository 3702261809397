<template>

    <v-dialog
        v-model="dialog"
        max-width="400"
    >
        <v-card>
            <v-toolbar color='primary' dark>
                <v-toolbar-title>
                    Nueva actualización
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-5">
                <v-textarea
                    v-model="detalle"
                    rounded
                    filled
                />

            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="error" @click="cancela">Cancelar</v-btn>
                <v-btn color="success" @click="guarda">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogOpen:Boolean,
        evento:Number,
    },
    data:()=>({
        detalle:''
    }),
    methods:{
        cancela(){
            this.dialog=false
        },
        async guarda(){
            this.loadingBtn=true
            try{
                let req =await this.$http({
                    url:'/calidad/guardaActualizacion',
                    method:'POST',
                    params:{
                        evento:this.evento,
                        detalles:this.detalle
                    }
                })
                this.loadingBtn=false
                if (req.data.estado){
                    this.$emit('agregado')
                    this.dialog = false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Revise los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingBtn=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogOpen
            },
            set(val){
                this.$emit('update:dialogOpen',val)
            },
        },
    },
    watch:{
        dialog(val){
            if (val){
                this.detalle = ''
            }
        },
    },
}
</script>

<style>
</style>